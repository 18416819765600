<template>
    <Dialog :header="`Edit School Details`"
           :draggable="false"  :modal="true" v-model:visible="visible" 
           :maximizable="false" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '40vw'}">

           <form ref="formData" @submit.prevent="handleSubmit(!v$.$invalid)"  class="pb-5 w-100 px-lg-10 fv-plugins-bootstrap fv-plugins-framework">
									<div class="pb-2">
										<div class="pb-10 pb-lg-15">
											<h3 class="font-weight-bold text-dark font-size-h2 text-center">School Details</h3>
											<div class="text-muted font-weight-bold font-size-h6 text-center">Setup School  and Subscription Details</div>
										</div>
										<div class="row">
											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Date</label>
													<input type="date" v-model="state.subscription_date" id="kt_daterangepicker_3" class="form-control h-auto p-3 border-0 rounded-md font-size-h5" placeholder="DD-MM-YYYYY" name="subscription_date" >
												<div class="fv-plugins-message-container"></div></div>
											</div>
											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Annual Subscription</label>
														<select name="annual_subscription"  v-model="state.annual_subscription" class="form-control h-auto p-3 border-0 rounded-md font-size-h6">
															<option value="">Select</option>
															<option value="Yes">Yes</option>
															<option value="No">No</option>
														</select>
													<div class="fv-plugins-message-container"></div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-lg-12 col-md-12">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Name of School</label>
													<input type="text" v-model="v$.name.$model" :class="{'p-invalid': isValid(v$.name) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="name" >
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.name)" class="p-error">{{ getError(v$.name, 'School Name')}}</span>
													</div>
												</div>
											</div>
										</div>

										<div class="row">
											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">School Website</label>
													<input type="text" v-model="v$.website.$model" :class="{'p-invalid': isValid(v$.website) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="website">
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.website)" class="p-error">{{ getError(v$.website, 'Website')}}</span>
													</div>
												</div>
											</div>

											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">School Email Address</label>
													<input type="text" v-model="v$.school_email.$model" :class="{'p-invalid': isValid(v$.school_email) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="school_email">
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.school_email)" class="p-error">{{ getError(v$.school_email, 'School Email Address')}}</span>
													</div>
												</div>
											</div>

											<div class="col-lg-12 col-md-12">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Street Address</label>
													<input type="text" v-model="v$.street_address.$model" :class="{'p-invalid': isValid(v$.street_address) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="street_address">
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.street_address)" class="p-error">{{ getError(v$.street_address, 'Street Address')}}</span>
													</div>
												</div>
											</div>

											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Suburb</label>
													<input type="text" v-model="v$.suburb.$model" :class="{'p-invalid': isValid(v$.suburb) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="suburb" >
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.suburb)" class="p-error">{{ getError(v$.suburb, 'Suburb')}}</span>
													</div>
												</div>
											</div>

												<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bolder text-dark">City</label>
													<input type="text" v-model="v$.city.$model" :class="{'p-invalid': isValid(v$.city) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="city" >
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.city)" class="p-error">{{ getError(v$.city, 'City')}}</span>
													</div>
												</div>
											</div>

											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">State</label>
													<input type="text" v-model="v$.state.$model" :class="{'p-invalid': isValid(v$.state) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="state">
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.state)" class="p-error">{{ getError(v$.state, 'State')}}</span>
													</div>
												</div>
											</div>

											<div class="col-12 col-lg-6 col-md-6">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Postcode</label>
													<input type="text" v-model="v$.zip.$model" :class="{'p-invalid': isValid(v$.zip) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="zip">
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.zip)" class="p-error">{{ getError(v$.zip, 'Post Code')}}</span>
													</div>
												</div>
											</div>

											<div class="col-12 col-lg-12 col-md-12">
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bolder text-dark">Country</label>
													<select name="country"  v-model="v$.country.$model" :class="{'p-invalid': isValid(v$.country) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6">
															<option value="">Select</option>
															<option v-for="(c, i) in countryList" :value="c.name" :key="i">{{c.name}}</option>
													</select>
													<div class="fv-plugins-message-container">
														 <span v-if="isValid(v$.country)" class="p-error">{{ getError(v$.country, 'Country')}}</span>
													</div>
												</div>
											</div>

											<div class="pb-5 pt-4 px-4" >
												<div class="pb-10 pb-lg-15">
													<h3 class="font-weight-bold text-dark font-size-h2 text-center">Contact Information</h3>
													<div class="text-muted font-weight-bold font-size-h6 text-center">Setup Contact Information</div>
												</div>
												<div class="form-group fv-plugins-icon-container">
													<label class="font-size-h6 font-weight-bold text-dark">Contact Name</label>
													<input type="text" v-model="v$.contact_name.$model" :class="{'p-invalid': isValid(v$.contact_name) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="contact_name">
													<div class="fv-plugins-message-container">
														<span v-if="isValid(v$.contact_name)" class="p-error">{{ getError(v$.contact_name, 'Contact Name')}}</span>
													</div>
												</div>
												<div class="row">
													<div class="col-lg-12 col-md-12">
														<div class="form-group fv-plugins-icon-container">
															<label class="font-size-h6 font-weight-bold text-dark">Position</label>
															<input type="text" v-model="v$.position.$model" :class="{'p-invalid': isValid(v$.position) }"  class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="position">
															<div class="fv-plugins-message-container">
																<span v-if="isValid(v$.position)" class="p-error">{{ getError(v$.position, 'Position')}}</span>
															</div>
														</div>
													</div>

													<div class="col-lg-6 col-md-6">
														<div class="form-group fv-plugins-icon-container">
															<label class="font-size-h6 font-weight-bold text-dark">Work Phone Number</label>
															<input type="text"  v-model="v$.work_phone.$model" :class="{'p-invalid': isValid(v$.work_phone) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="work_phone">
															<div class="fv-plugins-message-container">
																<span v-if="isValid(v$.work_phone)" class="p-error">{{ getError(v$.work_phone, 'Work Phone Number')}}</span>
															</div>
														</div>
													</div>

													<div class="col-lg-6 col-md-6">
														<div class="form-group fv-plugins-icon-container">
															<label class="font-size-h6 font-weight-bold text-dark">Mobile Phone Number</label>
															<input type="text" v-model="v$.mobile_phone.$model" :class="{'p-invalid': isValid(v$.mobile_phone) }" class="form-control h-auto p-3 border-0 rounded-md font-size-h6" name="mobile_phone" >
															<div class="fv-plugins-message-container">
																<span v-if="isValid(v$.mobile_phone)" class="p-error">{{ getError(v$.mobile_phone, 'Mobile Phone Number')}}</span>
															</div>
														</div>
													</div>

													<div class="col-lg-12 col-md-12">
														<div class="form-group fv-plugins-icon-container">
															<label class="font-size-h6 font-weight-bold text-dark">Email</label>
															<input type="email" v-model="v$.email.$model" :class="{'p-invalid': isValid(v$.email) }"  class="form-control h-auto p-4 border-0 rounded-lg font-size-h6" name="email" >
															<div class="fv-plugins-message-container">
																<span v-if="isValid(v$.email)" class="p-error">{{ getError(v$.email, 'Email')}}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="text-center w-100 pt-3">
										<div>
											<button type="submit" class="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-8 py-4 my-3">
												<span>Submit </span>
										  </button>
										</div>
									</div>
								<div>
                            </div>
                </form>
    </Dialog>
</template>
<script setup>
    import { defineComponent, ref, watch,  inject, computed, reactive, provide } from 'vue'  
    import Dialog from 'primevue/dialog';
    import { email, required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import  SchoolsOnboarding  from '@/composables/schools/SchoolsOnboarding'
    import { ElLoading } from 'element-plus'

    const props = defineProps({
        selectedSchool: {
            type: Object,
            required: true
        }
    })

    const { utils, store, toast, http, constants } = inject('App') 

    const { loading, response, updateSchool } = SchoolsOnboarding(http, constants)

    const countryList = ref([ 
			{name: 'Afghanistan', code: 'AF'}, 
			{name: 'Åland Islands', code: 'AX'}, 
			{name: 'Albania', code: 'AL'}, 
			{name: 'Algeria', code: 'DZ'}, 
			{name: 'American Samoa', code: 'AS'}, 
			{name: 'AndorrA', code: 'AD'}, 
			{name: 'Angola', code: 'AO'}, 
			{name: 'Anguilla', code: 'AI'}, 
			{name: 'Antarctica', code: 'AQ'}, 
			{name: 'Antigua and Barbuda', code: 'AG'}, 
			{name: 'Argentina', code: 'AR'}, 
			{name: 'Armenia', code: 'AM'}, 
			{name: 'Aruba', code: 'AW'}, 
			{name: 'Australia', code: 'AU'}, 
			{name: 'Austria', code: 'AT'}, 
			{name: 'Azerbaijan', code: 'AZ'}, 
			{name: 'Bahamas', code: 'BS'}, 
			{name: 'Bahrain', code: 'BH'}, 
			{name: 'Bangladesh', code: 'BD'}, 
			{name: 'Barbados', code: 'BB'}, 
			{name: 'Belarus', code: 'BY'}, 
			{name: 'Belgium', code: 'BE'}, 
			{name: 'Belize', code: 'BZ'}, 
			{name: 'Benin', code: 'BJ'}, 
			{name: 'Bermuda', code: 'BM'}, 
			{name: 'Bhutan', code: 'BT'}, 
			{name: 'Bolivia', code: 'BO'}, 
			{name: 'Bosnia and Herzegovina', code: 'BA'}, 
			{name: 'Botswana', code: 'BW'}, 
			{name: 'Bouvet Island', code: 'BV'}, 
			{name: 'Brazil', code: 'BR'}, 
			{name: 'British Indian Ocean Territory', code: 'IO'}, 
			{name: 'Brunei Darussalam', code: 'BN'}, 
			{name: 'Bulgaria', code: 'BG'}, 
			{name: 'Burkina Faso', code: 'BF'}, 
			{name: 'Burundi', code: 'BI'}, 
			{name: 'Cambodia', code: 'KH'}, 
			{name: 'Cameroon', code: 'CM'}, 
			{name: 'Canada', code: 'CA'}, 
			{name: 'Cape Verde', code: 'CV'}, 
			{name: 'Cayman Islands', code: 'KY'}, 
			{name: 'Central African Republic', code: 'CF'}, 
			{name: 'Chad', code: 'TD'}, 
			{name: 'Chile', code: 'CL'}, 
			{name: 'China', code: 'CN'}, 
			{name: 'Christmas Island', code: 'CX'}, 
			{name: 'Cocos (Keeling) Islands', code: 'CC'}, 
			{name: 'Colombia', code: 'CO'}, 
			{name: 'Comoros', code: 'KM'}, 
			{name: 'Congo', code: 'CG'}, 
			{name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
			{name: 'Cook Islands', code: 'CK'}, 
			{name: 'Costa Rica', code: 'CR'}, 
			{name: 'Cote D\'Ivoire', code: 'CI'}, 
			{name: 'Croatia', code: 'HR'}, 
			{name: 'Cuba', code: 'CU'}, 
			{name: 'Cyprus', code: 'CY'}, 
			{name: 'Czech Republic', code: 'CZ'}, 
			{name: 'Denmark', code: 'DK'}, 
			{name: 'Djibouti', code: 'DJ'}, 
			{name: 'Dominica', code: 'DM'}, 
			{name: 'Dominican Republic', code: 'DO'}, 
			{name: 'Ecuador', code: 'EC'}, 
			{name: 'Egypt', code: 'EG'}, 
			{name: 'El Salvador', code: 'SV'}, 
			{name: 'Equatorial Guinea', code: 'GQ'}, 
			{name: 'Eritrea', code: 'ER'}, 
			{name: 'Estonia', code: 'EE'}, 
			{name: 'Ethiopia', code: 'ET'}, 
			{name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
			{name: 'Faroe Islands', code: 'FO'}, 
			{name: 'Fiji', code: 'FJ'}, 
			{name: 'Finland', code: 'FI'}, 
			{name: 'France', code: 'FR'}, 
			{name: 'French Guiana', code: 'GF'}, 
			{name: 'French Polynesia', code: 'PF'}, 
			{name: 'French Southern Territories', code: 'TF'}, 
			{name: 'Gabon', code: 'GA'}, 
			{name: 'Gambia', code: 'GM'}, 
			{name: 'Georgia', code: 'GE'}, 
			{name: 'Germany', code: 'DE'}, 
			{name: 'Ghana', code: 'GH'}, 
			{name: 'Gibraltar', code: 'GI'}, 
			{name: 'Greece', code: 'GR'}, 
			{name: 'Greenland', code: 'GL'}, 
			{name: 'Grenada', code: 'GD'}, 
			{name: 'Guadeloupe', code: 'GP'}, 
			{name: 'Guam', code: 'GU'}, 
			{name: 'Guatemala', code: 'GT'}, 
			{name: 'Guernsey', code: 'GG'}, 
			{name: 'Guinea', code: 'GN'}, 
			{name: 'Guinea-Bissau', code: 'GW'}, 
			{name: 'Guyana', code: 'GY'}, 
			{name: 'Haiti', code: 'HT'}, 
			{name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
			{name: 'Holy See (Vatican City State)', code: 'VA'}, 
			{name: 'Honduras', code: 'HN'}, 
			{name: 'Hong Kong', code: 'HK'}, 
			{name: 'Hungary', code: 'HU'}, 
			{name: 'Iceland', code: 'IS'}, 
			{name: 'India', code: 'IN'}, 
			{name: 'Indonesia', code: 'ID'}, 
			{name: 'Iran, Islamic Republic Of', code: 'IR'}, 
			{name: 'Iraq', code: 'IQ'}, 
			{name: 'Ireland', code: 'IE'}, 
			{name: 'Isle of Man', code: 'IM'}, 
			{name: 'Israel', code: 'IL'}, 
			{name: 'Italy', code: 'IT'}, 
			{name: 'Jamaica', code: 'JM'}, 
			{name: 'Japan', code: 'JP'}, 
			{name: 'Jersey', code: 'JE'}, 
			{name: 'Jordan', code: 'JO'}, 
			{name: 'Kazakhstan', code: 'KZ'}, 
			{name: 'Kenya', code: 'KE'}, 
			{name: 'Kiribati', code: 'KI'}, 
			{name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
			{name: 'Korea, Republic of', code: 'KR'}, 
			{name: 'Kuwait', code: 'KW'}, 
			{name: 'Kyrgyzstan', code: 'KG'}, 
			{name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
			{name: 'Latvia', code: 'LV'}, 
			{name: 'Lebanon', code: 'LB'}, 
			{name: 'Lesotho', code: 'LS'}, 
			{name: 'Liberia', code: 'LR'}, 
			{name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
			{name: 'Liechtenstein', code: 'LI'}, 
			{name: 'Lithuania', code: 'LT'}, 
			{name: 'Luxembourg', code: 'LU'}, 
			{name: 'Macao', code: 'MO'}, 
			{name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
			{name: 'Madagascar', code: 'MG'}, 
			{name: 'Malawi', code: 'MW'}, 
			{name: 'Malaysia', code: 'MY'}, 
			{name: 'Maldives', code: 'MV'}, 
			{name: 'Mali', code: 'ML'}, 
			{name: 'Malta', code: 'MT'}, 
			{name: 'Marshall Islands', code: 'MH'}, 
			{name: 'Martinique', code: 'MQ'}, 
			{name: 'Mauritania', code: 'MR'}, 
			{name: 'Mauritius', code: 'MU'}, 
			{name: 'Mayotte', code: 'YT'}, 
			{name: 'Mexico', code: 'MX'}, 
			{name: 'Micronesia, Federated States of', code: 'FM'}, 
			{name: 'Moldova, Republic of', code: 'MD'}, 
			{name: 'Monaco', code: 'MC'}, 
			{name: 'Mongolia', code: 'MN'}, 
			{name: 'Montserrat', code: 'MS'}, 
			{name: 'Morocco', code: 'MA'}, 
			{name: 'Mozambique', code: 'MZ'}, 
			{name: 'Myanmar', code: 'MM'}, 
			{name: 'Namibia', code: 'NA'}, 
			{name: 'Nauru', code: 'NR'}, 
			{name: 'Nepal', code: 'NP'}, 
			{name: 'Netherlands', code: 'NL'}, 
			{name: 'Netherlands Antilles', code: 'AN'}, 
			{name: 'New Caledonia', code: 'NC'}, 
			{name: 'New Zealand', code: 'NZ'}, 
			{name: 'Nicaragua', code: 'NI'}, 
			{name: 'Niger', code: 'NE'}, 
			{name: 'Nigeria', code: 'NG'}, 
			{name: 'Niue', code: 'NU'}, 
			{name: 'Norfolk Island', code: 'NF'}, 
			{name: 'Northern Mariana Islands', code: 'MP'}, 
			{name: 'Norway', code: 'NO'}, 
			{name: 'Oman', code: 'OM'}, 
			{name: 'Pakistan', code: 'PK'}, 
			{name: 'Palau', code: 'PW'}, 
			{name: 'Palestinian Territory, Occupied', code: 'PS'}, 
			{name: 'Panama', code: 'PA'}, 
			{name: 'Papua New Guinea', code: 'PG'}, 
			{name: 'Paraguay', code: 'PY'}, 
			{name: 'Peru', code: 'PE'}, 
			{name: 'Philippines', code: 'PH'}, 
			{name: 'Pitcairn', code: 'PN'}, 
			{name: 'Poland', code: 'PL'}, 
			{name: 'Portugal', code: 'PT'}, 
			{name: 'Puerto Rico', code: 'PR'}, 
			{name: 'Qatar', code: 'QA'}, 
			{name: 'Reunion', code: 'RE'}, 
			{name: 'Romania', code: 'RO'}, 
			{name: 'Russian Federation', code: 'RU'}, 
			{name: 'RWANDA', code: 'RW'}, 
			{name: 'Saint Helena', code: 'SH'}, 
			{name: 'Saint Kitts and Nevis', code: 'KN'}, 
			{name: 'Saint Lucia', code: 'LC'}, 
			{name: 'Saint Pierre and Miquelon', code: 'PM'}, 
			{name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
			{name: 'Samoa', code: 'WS'}, 
			{name: 'San Marino', code: 'SM'}, 
			{name: 'Sao Tome and Principe', code: 'ST'}, 
			{name: 'Saudi Arabia', code: 'SA'}, 
			{name: 'Senegal', code: 'SN'}, 
			{name: 'Serbia and Montenegro', code: 'CS'}, 
			{name: 'Seychelles', code: 'SC'}, 
			{name: 'Sierra Leone', code: 'SL'}, 
			{name: 'Singapore', code: 'SG'}, 
			{name: 'Slovakia', code: 'SK'}, 
			{name: 'Slovenia', code: 'SI'}, 
			{name: 'Solomon Islands', code: 'SB'}, 
			{name: 'Somalia', code: 'SO'}, 
			{name: 'South Africa', code: 'ZA'}, 
			{name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
			{name: 'Spain', code: 'ES'}, 
			{name: 'Sri Lanka', code: 'LK'}, 
			{name: 'Sudan', code: 'SD'}, 
			{name: 'Suriname', code: 'SR'}, 
			{name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
			{name: 'Swaziland', code: 'SZ'}, 
			{name: 'Sweden', code: 'SE'}, 
			{name: 'Switzerland', code: 'CH'}, 
			{name: 'Syrian Arab Republic', code: 'SY'}, 
			{name: 'Taiwan, Province of China', code: 'TW'}, 
			{name: 'Tajikistan', code: 'TJ'}, 
			{name: 'Tanzania, United Republic of', code: 'TZ'}, 
			{name: 'Thailand', code: 'TH'}, 
			{name: 'Timor-Leste', code: 'TL'}, 
			{name: 'Togo', code: 'TG'}, 
			{name: 'Tokelau', code: 'TK'}, 
			{name: 'Tonga', code: 'TO'}, 
			{name: 'Trinidad and Tobago', code: 'TT'}, 
			{name: 'Tunisia', code: 'TN'}, 
			{name: 'Turkey', code: 'TR'}, 
			{name: 'Turkmenistan', code: 'TM'}, 
			{name: 'Turks and Caicos Islands', code: 'TC'}, 
			{name: 'Tuvalu', code: 'TV'}, 
			{name: 'Uganda', code: 'UG'}, 
			{name: 'Ukraine', code: 'UA'}, 
			{name: 'United Arab Emirates', code: 'AE'}, 
			{name: 'United Kingdom', code: 'GB'}, 
			{name: 'United States', code: 'US'}, 
			{name: 'United States Minor Outlying Islands', code: 'UM'}, 
			{name: 'Uruguay', code: 'UY'}, 
			{name: 'Uzbekistan', code: 'UZ'}, 
			{name: 'Vanuatu', code: 'VU'}, 
			{name: 'Venezuela', code: 'VE'}, 
			{name: 'Viet Nam', code: 'VN'}, 
			{name: 'Virgin Islands, British', code: 'VG'}, 
			{name: 'Virgin Islands, U.S.', code: 'VI'}, 
			{name: 'Wallis and Futuna', code: 'WF'}, 
			{name: 'Western Sahara', code: 'EH'}, 
			{name: 'Yemen', code: 'YE'}, 
			{name: 'Zambia', code: 'ZM'}, 
			{name: 'Zimbabwe', code: 'ZW'} 
	])

	const bg_image = require('@/assets/themes/assets/media/bg/bg-2.jpg')
    const submitted = ref(false)
    const formData = ref('')

    const state = reactive(props.selectedSchool)

    const rules = {
        name: { required },
        website: { required },
        school_email: { required, email },
        street_address: { required },
        suburb: { required },
        city: { required },
        state: { required },
        zip: { required },
        country: { required },
        contact_name: { required },
        position: { required },
        work_phone: { required },
        mobile_phone: { required },
        email: { required, email }
    }

    const v$ = useVuelidate(rules, state);

    function isValid(v) {
        return (v.$invalid && submitted.value) || v.$pending.$response
    }
    
    function getError(v, label) {
        return v.required.$message.replace('Value', label)
    }

    const handleSubmit = (isFormValid) => {
        submitted.value = true

        if (isFormValid) {
            submit()
        }
    }

    async function submit() {
        await updateSchool(formData.value, props.selectedSchool.id)
		visible.value = false
    }

    const visible = ref(true)
</script>
<style scoped>
    .form-control {
        background-color: #f3f6f9 !important;
    }
</style>