import { ref } from 'vue'
export default function SchoolsGetList(http, constant, store) {
    let loading = ref(false)
    let school_list = ref([])

    async function getSchoolsList() {
        loading.value = true;
        try {
            const response = await http.post(constant.schoolsgetlist, [])
            loading.value = false
            school_list.value = response.data.data
            store.commit('SchoolsModule/SET_SHOOLS_LIST', school_list.value)
        } catch (err) {
            loading.value = false;
        }
    }
    return {
        loading, school_list, getSchoolsList
    }
}