<template>
<div>
    <div class="d-flex flex-column-fluid">
        <div class="container">
            <div class="card card-custom">
                <div class="card-body" id="kt_content">
                    <DataTable  class="p-datatable-lg" ref="dt"  :value="school_list"
                        dataKey="id" :filters="filter.filter_list"  filterDisplay="menu"  :loading="loading"
                        :paginator="true" :rows="6"
                        :rowsPerPageOptions="[6, 10,20,50, 100, 1000]"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                            <template #header>
                            </template>
                            <Column field="id" header="ID"></Column>
                            <Column field="name" header="Name"></Column>
                            <Column field="contact_name" header="Contact Person"></Column>
                            <Column field="endpoints" header="End Points">
                                <template #body="props">
                                   {{  props.data.endpoints.code }} -  {{  props.data.endpoints.api }}
                                </template>
                            </Column>
                            <Column field="email" header="Email"></Column>
                            <Column field="created_at" header="Submitted At">
                                 <template #body="props">
                                    {{ moment(props.data.created_at).format('LL') }}
                                </template>
                            </Column>
                            <Column field="id" header="">
                                <template #body="slotProps">
                                    <a href="Javascript:void(0)" @click="edit(slotProps.data)" class="btn btn-sm btn-clean btn-icon mr-2 action-edit actions-table" title="Edit details">
                                        <span class="svg-icon svg-icon-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                    <a href="Javascript:void(0)" @click="deletePrompt(slotProps.data)" class="btn btn-sm btn-clean btn-icon action-delete actions-table" title="Delete">
                                        <span class="svg-icon svg-icon-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>
                                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </template>
                            </Column>

                            <template #loading>
                                Loading customers data. Please wait.
                            </template>
                            <template #empty>
                                No results found.
                            </template>
                        </DataTable>
                </div>
            </div>
        </div>
    </div>
    <AdminSchoolEdit v-if="showEchoolEditModal" :selectedSchool="selectedSchool"></AdminSchoolEdit>
</div>
</template>
<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { defineComponent, ref, onMounted, inject, reactive, watch, provide, computed } from 'vue'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag';
import { useConfirm } from "primevue/useconfirm"
import ConfirmDialog from 'primevue/confirmdialog'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import SelectButton from 'primevue/selectbutton'
import NoResultsError from '@/components/NoResultsError.vue'
import Paginator from 'primevue/paginator';

import moment from 'moment'

import  SchoolsGetList  from '@/composables/schools/SchoolsGetList'
import AdminSchoolEdit from './AdminSchoolEdit.vue'

export default defineComponent({
    components: { DataTable, Column, AdminSchoolEdit },
    setup() {
        const { store, toast, http, constants } = inject('App')
        const { loading, school_list, getSchoolsList } = SchoolsGetList(http, constants, store)

        const filter = reactive({
            filterList: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })

        const selectedSchool = ref([])
        const showEchoolEditModal = ref(false)
        const schoolList = ref([])

        function edit(s)
        {
            selectedSchool.value  = s
            showEchoolEditModal.value = true
        }

        function deletePrompt()
        {

        }

        onMounted(async () => {
            await getSchoolsList()
        })

        return {
            user: computed(() => store.getters['UserModule/GET_USER']),
            filter, schoolList, loading, school_list,
            moment,
            edit,
            deletePrompt,
            showEchoolEditModal,
            selectedSchool
        }
    },
})
</script>
